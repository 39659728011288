import React, { useState } from "react"
import * as faqStyles from '../styles/faq.module.scss'


const Faq = () => {

    const qa = 
    [
        [
            "Can I return it?", 
            `If you are not satisfied with the product, you may 
            return it within 30 days of purchase.`
        ],
        [
            "How much does the shipping cost?", 
            `The cost of shipping is already included in the purchase price.`
        ],
        [
            "Do you ship to my country?", 
            `Yes, we ship worldwide.`
        ],
        [
            "Do you offer discounts?", 
            `Yes, for larger quantities, we offer a quantity discount by arrangement.`
        ],
        [
            "What is the expected delivery time?", 
            `Delivery time depends on the number of products ordered and your location.`
        ],
    ]

    const [expanded, setExpanded] = useState(-1)

    const handleOnClick = (index) => {
        // if expanded element is clicked again, collapse it
        if (index === expanded)
            setExpanded(-1)
        else
            setExpanded(index)
    }

    const qaList = qa.map(
        (entry, index) => 
        <div 
            role={"switch"}
            aria-checked={index === expanded}
            tabIndex={0}
            key={index} 
            className={`${faqStyles.qaEntry} ${index === expanded ? faqStyles.qaActiveEntry : ""}`}
            onClick={ () => handleOnClick(index) }
            onKeyPress={ () => handleOnClick(index) }
        >
            <span>{entry[0]}</span>
            { 
                index === expanded 
                    ? <> <hr /> <span>{ entry[1] }</span> </>
                    : <></> 
            }
        </div>
    )

    return ( 
        <div className={faqStyles.faq} id="faq">
            <h2>Frequently asked questions</h2>
            <div className={faqStyles.colContainer}>
                <div className={faqStyles.qaCol}>
                    { qaList.filter((_, i) => i % 2 === 0) }
                </div>
                <div className={faqStyles.qaCol}>
                    { qaList.filter((_, i) => i % 2 !== 0) }
                </div>
            </div>
        </div>
    )
}

export default Faq
