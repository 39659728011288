import React, { useState } from "react"
import Seo from "../components/SEO"
import { Container, Row, Col } from 'react-bootstrap'
import * as contactStyles from '../styles/contact.module.scss'
import ContactForm from '../components/contactForm'

import Faq from '../components/faq'


const ThankYouMessage = () =>
    <div className={contactStyles.response}>
        <p> Message sent </p>
        <p>
            Your message has been received. 
            We will message you back as soon as possible.
        </p>
    </div>


const Contact = () => {

    const [submitted, setSubmitted] = useState(false)

    return (
        <>
            <Seo title="Contact" />
            <Container className={contactStyles.contactWrapper}>
                <h1> CONTACT US </h1>
                <Row>
                    <Col lg={6} md={6}>
                        <div className={contactStyles.contactText}>
                            <p>Read the <a href="#faq">FAQ</a> we have prepared for you.</p>
                            <p>
                                For questions and inquiries, use this contact form 
                                to contact us. We will gladly reply as soon as possible.
                            </p>
                            <p>
                                You can also call us at +386 51 625 375
                            </p>
                        </div>
                    </Col>
                    <Col lg={6} md={6}>
                        {
                            submitted 
                                ? <ThankYouMessage /> 
                                : <ContactForm setSubmitted={setSubmitted} />
                        }
                    </Col>
                </Row>
                <Faq />
            </Container>
        </>
    )
}


export default Contact