import React, { useRef } from "react"
import { Formik } from 'formik'
import * as contactStyles from '../styles/contact.module.scss'
import { Button } from 'react-bootstrap'


const ContactForm = ({setSubmitted}) => {

    const formRef = useRef()

    return (
        <div className={contactStyles.contactForm}>
            <Formik
                initialValues={{ sender_name: '', sender_email: '', sender_message: ''}}
                validate={
                    (values) => {
                        var errors = {}

                        if (values.sender_name === '')
                            errors.sender_name = "*Name is required"

                        let emailRE = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/

                        if (values.sender_email === '')
                            errors.sender_email = "*Email is required"
                        else if(!emailRE.test(values.sender_email))
                            errors.sender_email = "*Email is invalid"
                        
                        if(values.sender_message === '')
                            errors.sender_message = "*Message is required"

                        return errors
                    }
                }
            >
                {({ values, errors, touched, handleChange, handleBlur, isValid }) =>
                    <form 
                        name="contact v2" 
                        method="POST" 
                        data-netlify="true" 
                        className="form-group"
                        ref={formRef}
                        noValidate
                        onSubmit={
                            (event) => {
                                event.preventDefault()
                                let formData = new FormData(formRef.current)
                                fetch('/', {
                                    method: 'POST',
                                    headers: { "Content-Type": "application/x-www-form-urlencoded" },
                                    body: new URLSearchParams(formData).toString()
                                }).then(
                                    () => setSubmitted(true)
                                )
                            }
                        }
                    >
                        <input type="hidden" name="form-name" value="contact v2" />   
                        <label> Your name
                            <span className={contactStyles.validateError}>
                                { errors.sender_name && touched.sender_name && errors.sender_name }
                            </span>
                            <input 
                                type="text" 
                                name="sender_name" 
                                className="form-control" 
                                placeholder="Enter your name" 
                                value={values.sender_name}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                        </label>
                        <label> Your email
                            <span className={contactStyles.validateError}>
                                { errors.sender_email && touched.sender_email && errors.sender_email }
                            </span>
                            <input 
                                type="email" 
                                name="sender_email" 
                                className="form-control" 
                                placeholder="Enter your email" 
                                value={values.sender_email}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                        </label>
                        <label> Message
                            <span className={contactStyles.validateError}>
                                { errors.sender_message && touched.sender_message && errors.sender_message }
                            </span>
                            <textarea 
                                rows="7" 
                                name="sender_message" 
                                className="form-control" 
                                placeholder="Enter your message" 
                                value={values.sender_message}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                        </label>
                        <Button type="submit" disabled={ (!isValid) || (Object.keys(touched).length === 0) }>
                            SEND
                        </Button>
                    </form>
                }
            </Formik>
        </div>
    )
}


export default ContactForm
